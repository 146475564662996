import React from 'react';
import introAnim from '../videos/intro.mp4';
import { Link } from 'react-router-dom';

const Intro = () => {
    return (
        <>
            <div className="m-auto p-2 md:p-12 h-5/6 bg-secondary" id='about' >

                <section className="flex flex-col lg:flex-row py-8 justify-between lg:text-left mb-12" data-aos="fade-up">
                    <div className="lg:w-3/4 flex flex-col lg:mx-4 justify-center">
                        <video alt="card img" className="rounded-t float-left" autoPlay loop muted>
                            <source src={introAnim} type="video/mp4" />
                        </video>
                    </div>
                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                        <h3 className="font-heading text-heading xs:text-[1.25rem] xsm:text-[1.5rem] sm:text-[1.75rem] md:text-[2rem] lg:text-[2rem] xl:text-[2rem] text-primary">We develop high quality web and mobile applications for organizations, institutions and SMEs</h3>
                        <div>
                            <p className='my-8 font-subheading text-subheading xs:text-[1rem] xsm:text-[1.25rem] sm:text-[1.5rem] md:text-[1.5rem] lg:text-[1.5rem] xl:text-[1.5rem] text-greyText font-semibold'>Our team is well vast in software development and is ready to help develop the applications of your choice.</p>
                        </div>

                        <Link to="/contact" className="text-secondary font-body text-body xs:text-[0.75rem] xsm:text-[0.875rem] sm:text-[1rem] md:text-[1rem] lg:text-[1rem] xl:text-[1rem] bg-primary hover:bg-orange-600 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group">
                            Contact us
                            <svg className="w-4 h-4 ml-1 group-hover: translate-x-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </Link>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Intro;