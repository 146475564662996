import React from 'react';
import img from '../images/web.svg';
import img2 from '../images/app.svg';
import img3 from '../images/hosting.svg';
import img4 from '../images/consultation.svg';

const Services = () => {
    const services = [
        {
            title: "Web Development",
            description: "We specialize in creating and optimizing high-quality, custom websites for businesses and organizations of all sizes. Building mobile-friendly and easy-to-use websites and applications for clients.",
            img: img
        },
        {
            title: "Mobile Development",
            description: "We develop high-quality, custom cross-platform mobile applications that are robust and optimized for performance, scalability, and accessibility.",
            img: img2
        },
        {
            title: "Domain and Hosting Services",
            description: "We provide domain registration and web hosting services to individuals and organizations to enable them gain visibility in the digital space.",
            img: img3
        },
        {
            title: "General IT Consultations",
            description: "Our IT consultations service provides expert guidance and support for businesses and individuals looking to optimize their technology solutions.",
            img: img4
        },
    ]
    const aboutData = [
        { icon: 'Build', title: 'Build', description: 'With our years of experience in software analysis and design and a deep understanding of the latest IT trends and solutions, we provide customized recommendations and strategies to help you improve your operations, reduce costs, and increase efficiency.' },
        { icon: 'Collaborate', title: 'Collaborate', description: 'We can collaborate with your existing tech team to scale existing software applications or design customized software applications that suits your everyday need and simplifies various processes.' },
    ]
    return (
        <div id="services" className="bg-gradient-to-r from-primary to-[#FF8C42]" >
            <section data-aos="zoom-in-down">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center font-heading text-heading xs:text-[1.25rem] xsm:text-[1.5rem] sm:text-[1.75rem] md:text-[2rem] lg:text-[2rem] xl:text-[2rem] text-secondary uppercase font-bold">services</h2>

                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-secondary'></div>
                    </div>
                    <h2 className="mt-4 mx-12 text-center font-subheading text-subheading xs:text-[1rem] xsm:text-[1.25rem] sm:text-[1.5rem] md:text-[1.5rem] lg:text-[1.5rem] xl:text-[1.5rem] text-secondary font-semibold">We are deeply committed to the growth and success of our clients.</h2>
                </div>

                <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
                        {services.map(service => (
                            <div key={service.title} className="bg-secondary transition-all ease-in-out duration-400  overflow-hidden text-greyText hover:bg-gray-700 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={service.img} />
                                    <h2 className="font-semibold my-4 font-sectionHeader text-sectionHeader xs:text-[0.875rem] xsm:text-[1rem] sm:text-[1.125rem] md:text-[1.25rem] lg:text-[1.25rem] xl:text-[1.25rem] text-center ">{service.title}</h2>
                                    <p className="font-body text-body xs:text-[0.75rem] xsm:text-[0.875rem] sm:text-[1rem] md:text-[1rem] lg:text-[1rem] xl:text-[1rem] hover:text-white">{service.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section className='my-16' data-aos="zoom-in-down">
                    <div className="m-auto md:p-12 h-5/6 bg-greyText border-y-8  border-primary">
                        <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="zoom-out">
                            {aboutData.map(({ icon, title, description }, index) => (
                                <div key={index} className="lg:w-1/2 flex mx-4 flex-col lg:mx-4 justify-center">
                                    <div className='text-primary mb-4'>
                                        {icon === 'Build' ?
                                            <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" className='fill-current'><path d="M2 12h2a7.986 7.986 0 0 1 2.337-5.663 7.91 7.91 0 0 1 2.542-1.71 8.12 8.12 0 0 1 6.13-.041A2.488 2.488 0 0 0 17.5 7C18.886 7 20 5.886 20 4.5S18.886 2 17.5 2c-.689 0-1.312.276-1.763.725-2.431-.973-5.223-.958-7.635.059a9.928 9.928 0 0 0-3.18 2.139 9.92 9.92 0 0 0-2.14 3.179A10.005 10.005 0 0 0 2 12zm17.373 3.122c-.401.952-.977 1.808-1.71 2.541s-1.589 1.309-2.542 1.71a8.12 8.12 0 0 1-6.13.041A2.488 2.488 0 0 0 6.5 17C5.114 17 4 18.114 4 19.5S5.114 22 6.5 22c.689 0 1.312-.276 1.763-.725A9.965 9.965 0 0 0 12 22a9.983 9.983 0 0 0 9.217-6.102A9.992 9.992 0 0 0 22 12h-2a7.993 7.993 0 0 1-.627 3.122z"></path><path d="M12 7.462c-2.502 0-4.538 2.036-4.538 4.538S9.498 16.538 12 16.538s4.538-2.036 4.538-4.538S14.502 7.462 12 7.462zm0 7.076c-1.399 0-2.538-1.139-2.538-2.538S10.601 9.462 12 9.462s2.538 1.139 2.538 2.538-1.139 2.538-2.538 2.538z"></path></svg>
                                            :
                                            <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" className='fill-current'><path d="M2 12h2a7.986 7.986 0 0 1 2.337-5.663 7.91 7.91 0 0 1 2.542-1.71 8.12 8.12 0 0 1 6.13-.041A2.488 2.488 0 0 0 17.5 7C18.886 7 20 5.886 20 4.5S18.886 2 17.5 2c-.689 0-1.312.276-1.763.725-2.431-.973-5.223-.958-7.635.059a9.928 9.928 0 0 0-3.18 2.139 9.92 9.92 0 0 0-2.14 3.179A10.005 10.005 0 0 0 2 12zm17.373 3.122c-.401.952-.977 1.808-1.71 2.541s-1.589 1.309-2.542 1.71a8.12 8.12 0 0 1-6.13.041A2.488 2.488 0 0 0 6.5 17C5.114 17 4 18.114 4 19.5S5.114 22 6.5 22c.689 0 1.312-.276 1.763-.725A9.965 9.965 0 0 0 12 22a9.983 9.983 0 0 0 9.217-6.102A9.992 9.992 0 0 0 22 12h-2a7.993 7.993 0 0 1-.627 3.122z"></path><path d="M12 7.462c-2.502 0-4.538 2.036-4.538 4.538S9.498 16.538 12 16.538s4.538-2.036 4.538-4.538S14.502 7.462 12 7.462zm0 7.076c-1.399 0-2.538-1.139-2.538-2.538S10.601 9.462 12 9.462s2.538 1.139 2.538 2.538-1.139 2.538-2.538 2.538z"></path></svg>
                                        }
                                    </div>
                                    <h3 className="font-heading text-heading xs:text-[1.25rem] xsm:text-[1.5rem] sm:text-[1.75rem] md:text-[2rem] lg:text-[2rem] xl:text-[2rem]  text-primary
-                            font-bold">We <span className='font-black'>{title}</span></h3>
                                    <div>
                                        <p className='my-3  font-body text-body xs:text-[0.75rem] xsm:text-[0.875rem] sm:text-[1rem] md:text-[1rem] lg:text-[1rem] xl:text-[1rem] text-secondary font-semibold'>
                                            {description}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
        </div>
    )
}

export default Services;