import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import linkdinLogo from '../images/linkedin.svg';
import instaLogo from '../images/insta.svg';
const Footer = () => {
    return (
        <>
            <footer className='bg-greyText border-y-8  border-primary'>
                <div className="footer max-w-full mx-auto px-4 sm:px-6 py-30">

                    {/* Top area: Blocks */}
                    <div className="grid sm:grid-cols-12 gap-5 py-8 md:py-12 lg:ml-11">

                        {/* 1st block */}
                        <div className="col-span-12 lg:col-span-4">
                            <div className="bg-greyText box-border border-b-0 border-primary p-2 border-x-4 border-x-white text-secondary text-center rounded-lg xl:w-80 mx-auto">
                                <h3 className="font-heading text-heading xs:text-[1.25rem] xsm:text-[1.5rem] sm:text-[1.75rem] md:text-[2rem] lg:text-[2rem] xl:text-[2rem] border-y-4 text-secondary border-primary mb-4">Technodevotion</h3>
                                <div className='font-medium font-body text-body xs:text-[0.75rem] xsm:text-[0.875rem] sm:text-[1rem] md:text-[1rem] lg:text-[1rem] xl:text-[1rem] text-secondary'>
                                    <h5>Technodevotion LLP,</h5>
                                    <p>Iscon park, Satellite road</p>
                                    <p>Ahmedabad - 380015.</p>
                                    <p>Gujarat, India</p>
                                </div>
                            </div>
                        </div>

                        {/* 2nd block */}
                        <div className="col-span-6 md:col-span-6 lg:col-span-1 ml-7 mx-auto">
                            <h6 className="font-bold text-primary font-sectionHeader text-sectionHeader xs:text-[0.875rem] xsm:text-[1rem] sm:text-[1.125rem] md:text-[1.25rem] lg:text-[1.25rem] xl:text-[1.25rem] mb-4">LINKS</h6>
                            <ul className="font-body text-body xs:text-[0.75rem] xsm:text-[0.875rem] sm:text-[1rem] md:text-[1rem] lg:text-[1rem] xl:text-[1rem]">
                                <li className="mb-2">
                                    <HashLink to="#" className="text-secondary hover:text-primary  hover:tracking-wider transition duration-250 ease-in-out">About</HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink to="#" className="text-secondary hover:text-primary  hover:tracking-wider transition duration-250 ease-in-out">Services</HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink to="#" className="text-secondary hover:text-primary  hover:tracking-wider transition duration-250 ease-in-out">Contact</HashLink>
                                </li>
                            </ul>
                        </div>

                        {/* 3rd block */}
                        <div className="col-span-6 md:col-span-6 lg:col-span-4 mx-auto">
                            <h6 className="text-primary font-sectionHeader text-sectionHeader xs:text-[0.875rem] xsm:text-[1rem] sm:text-[1.125rem] md:text-[1.25rem] lg:text-[1.25rem] xl:text-[1.25rem] font-bold mb-4">OUR SERVICES</h6>
                            <ul className="font-body text-body xs:text-[0.75rem] xsm:text-[0.875rem] sm:text-[1rem] md:text-[1rem] lg:text-[1rem] xl:text-[1rem]">
                                <li className="mb-2">
                                    <Link to="#" className="text-secondary hover:text-primary  hover:tracking-wider transition duration-250 ease-in-out">Web Development</Link>
                                </li>
                                <li className="mb-2">
                                    <Link to="#" className="text-secondary hover:text-primary  hover:tracking-wider transition duration-250 ease-in-out">Mobile App Development</Link>
                                </li>
                                <li className="mb-2">
                                    <Link to="#" className="text-secondary hover:text-primary  hover:tracking-wider transition duration-250 ease-in-out">Domain and Hosting</Link>
                                </li>
                                <li className="mb-2">
                                    <Link to="#" className="text-secondary hover:text-primary  hover:tracking-wider transition duration-250 ease-in-out">General IT Consultations</Link>
                                </li>
                            </ul>
                        </div>

                        {/* 4th block */}
                        <div className="col-span-12 text-center mx-auto lg:col-span-3 font-bold uppercase text-blue-900">
                            <div className="text-primary font-sectionHeader text-sectionHeader xs:text-[0.875rem] xsm:text-[1rem] sm:text-[1.125rem] md:text-[1.25rem] lg:text-[1.25rem] xl:text-[1.25rem] mb-6">
                                Social Media Links.
                            </div>

                            <div className="font-body text-secondary text-body xs:text-[0.75rem] xsm:text-[0.875rem] sm:text-[1rem] md:text-[1rem] lg:text-[1rem] xl:text-[1rem] font-medium mb-6">
                                Follow us on social media.
                            </div>
                            <div className="mx-auto text-center mt-2">
                                <ul className="flex justify-center mb-4 md:mb-0">
                                    <li>
                                        <Link to="#" className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-white-100 h-8 w-8 shadow transition duration-150 ease-in-out" aria-label="Facebook">
                                            <img src={instaLogo} alt="Instagram logo" />
                                        </Link>
                                    </li>
                                    <li className="ml-4">
                                        <Link to="#" className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-white-100 h-8 w-8 shadow transition duration-150 ease-in-out" aria-label="Facebook">
                                            <img src={linkdinLogo} alt="LinkedIn logo" />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>

                    <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
                        <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
                            <div className="text-sm text-gray-200 font-semibold py-1">
                                Copyright &copy; {new Date().getFullYear()}{"  "}
                                <HashLink
                                    to="#"
                                    className=" hover:text-primary "
                                >
                                    Technodevotion LLP
                                </HashLink>. All rights reserved.
                            </div>
                        </div>
                    </div>

                </div>

            </footer>
        </>
    )
}
export default Footer;
