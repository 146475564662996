import React from 'react';
import NavBar from '../components/Navbar/NavBar';
import headerVideo from '../videos/header_low.mp4';
import headerButton from '../images/header_button.svg'
const Hero = () => {
    return (
        <>
            <div className="hero" id='hero'>
                <div>
                    <NavBar />
                </div>

                <div className="m-auto overflow-hidden mt-8 lg:mt-4  h-5/6">

                    <div id='hero' className=" bg-primary flex flex-col lg:flex-row justify-between text-center lg:text-left">
                        <video className="mb-5 w-full lg:h-1/6 object-center  object-cover" autoPlay loop muted>
                            <source src={headerVideo} type="video/mp4" />
                        </video>
                        <div className="absolute inset-0 xs:bottom-0 xsm:-bottom-80 sm:bottom-80 md:bottom-80 lg:bottom-60 xl:bottom-40 flex items-end justify-center" data-aos="zoom-in" data-aos-delay="500">
                        <a href="#about" className="inline-flex items-center justify-center rounded-full xs:w-8 xs:h-8 xsm:w-8 xsm:h-8 sm:w-10 sm:h-10 md:w-10 md:h-10 lg:w-12 lg:h-12 xl:w-12 xl:h-12 group">
                        <img alt="card img" src={headerButton} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Hero;